<template>
  <div class="tabbar">
    <van-tabbar
      v-model="active"
      :active-color="active_color"
      :inactive-color="inactive_color"
      :safe-area-inset-bottom="true"
    >
      <van-tabbar-item
        @click="onHome"
        name="home"
        :icon="active == 'home' ? home_actived : home_unselected"
        class="tabBar_item"
        >首页</van-tabbar-item
      >
      <van-tabbar-item
        @click="onShortVideo"
        name="shortVideo"
        :icon="
          active == 'shortVideo' ? shortvideo_actived : shortvideo_unselected
        "
        >短视频</van-tabbar-item
      >
      <van-tabbar-item
        replace
        to="/loufeng"
        name="loufeng"
        :icon="active == 'loufeng' ? loufeng_actived : loufeng_unselected"
        >{{ h5PageUrl ? "福利" : "楼凤" }}</van-tabbar-item
      >
      <van-tabbar-item
        @click="onDemand"
        name="onDemand"
        :icon="active == 'onDemand' ? ondemnd_actived : ondemnd_unselected"
        >点播</van-tabbar-item
      >
      <van-tabbar-item
            replace
            to="/videoLibrary"
            name="videoLibrary"
            :icon="active == 'videoLibrary' ? videoLibrary_actived : videoLibrary_unselected"
        >片库</van-tabbar-item>
      <!-- <van-tabbar-item replace to="/game" name="game" :icon="getGameIcon()"
        >游戏</van-tabbar-item
      > -->
      <van-tabbar-item
        replace
        to="/mine"
        name="mine"
        :icon="active == 'mine' ? mine_actived : mine_unselected"
        >我的</van-tabbar-item
      >
    </van-tabbar>
    <!-- <div class="getVip" v-if="userInfo.giftVipStatus == 1">
      <svg-icon iconClass="fanxian" class="fanxian"></svg-icon>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      active: "home",
      home_unselected: require("@/assets/png/home_unselected.png"),
      home_actived: require("@/assets/png/home_actived.png"),
      shortvideo_unselected: require("@/assets/png/ondemnd_unselected.png"),
      shortvideo_actived: require("@/assets/png/ondemnd_actived.png"),
      ondemnd_unselected: require("@/assets/png/ondemnd_unselected.png"),
      ondemnd_actived: require("@/assets/png/ondemnd_actived.png"),
      videoLibrary_unselected: require("@/assets/png/videoLibrary_unselected.png"),
      videoLibrary_actived: require("@/assets/png/videoLibrary_actived.png"),
      game_unselected: require("@/assets/png/game_unselected.png"),
      wordCup: require("@/assets/png/world_cup.png"),
      wordCup_actived: require("@/assets/png/wordCupActived.png"),
      game_actived: require("@/assets/png/game_actived.png"),
      loufeng_unselected: require("@/assets/png/loufeng_unselected.png"),
      loufeng_actived: require("@/assets/png/loufeng_actived.png"),
      mine_unselected: require("@/assets/png/mine_unselected.png"),
      mine_actived: require("@/assets/png/mine_actived.png"),
      active_color: "#f21313",
      inactive_color: "#565656",
      fanxian: require("@/assets/png/fanxian.png"),
      h5PageUrl: "",
    };
  },
  watch: {
    $route: function (val) {
      this.refreshRoute(val.path);
    },
  },
  created() {
    this.h5PageUrl = JSON.parse(sessionStorage.getItem("appConfig")).louFengH5;
    this.refreshRoute(this.$route.path);
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
      waliWorldCupSwitch: "waliWorldCupSwitch",
    }),
  },
  methods: {
    // 刷新路由
    refreshRoute(path) {
      if (path.match(/\/(\S*)\//)) {
        this.active = path.match(/\/(\S*)\//)[1];
      } else {
        this.active = this.$route.name;
      }
    },
    // 点击点播
    onDemand() {
      let path = sessionStorage.getItem("onDemand") || "/onDemand/recommend";
      this.$router.replace(path);
    },
    onHome() {
      let path = sessionStorage.getItem("homeTab") || "/home/introduce";
      this.$router.replace(path);
    },
    onShortVideo() {
      let path = sessionStorage.getItem("shortVideo") || "/shortVideo";
      this.$router.replace(path);
    },
    getGameIcon() {
      if (this.waliWorldCupSwitch) {
        return this.active == "game" ? this.wordCup_actived : this.wordCup;
      }
      return this.active == "game" ? this.game_actived : this.game_unselected;
    },
  },
};
</script>
<style lang="scss" scoped>
.tabbar {
  position: relative;
  .getVip {
    position: fixed;
    left: 50%;
    bottom: 34px;
    margin-left: 20px;
    z-index: 1;
    .fanxian {
      font-size: 36px;
    }
  }
}
.tabBar_item {
  font-size: 12px;
}
</style>

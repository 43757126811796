import axios from "@/utils/request";

//首页接口开始
//首页推荐页面接口
export function iconList(data) {
  return axios.get(`/media/most`, {
    params: data
  });
}

//首页专区接口
export function tagList(data) {
  return axios.post(`/media/list`, data);
}

//首页排行接口
export function rankList(data){
  return axios.get(`/media/ranking`, {params: data});
}

//首页博主排行榜
export function userRank(data){
  return axios.get(`/blogger/ranking`, {params: data});
}

//首页专区接口（新添加）
export function newTagList(data){
  return axios.get(`/media/area`, {params: data});
}

//博主榜视频列表
export function getUserList(data){
  return axios.post(`/media/user`, data);
}

//热搜主题
export function getSearchHot(data){
  return axios.post(`/search/pop`, data);
}

//热搜标签
export function getHotTags(data){
  return axios.get(`/media/sexArea/tags/hot`, {params: data});
}

//查询指定标签的媒体
export function getTagsMedia(data){
  return axios.post(`/media/tag`, data);
}

//热搜博主
export function geHotBloggers(data){
  return axios.post(`/blogger/hottest`, data);
}

//相关博主搜索 
export function getBloggers(data){
  return axios.post(`/search/bloggers`, data);
}

//相关视频搜索
export function getMedias(data){
  return axios.post(`/search/medias`, data);
}

//获取话题列表
export function getTopiclist(data){
  return axios.post(`/media/topiclist`, data);
}

//查询指定话题媒体
export function getTopic(data){
  return axios.post(`/media/topic`, data);
}

//关注/取消关注
export function userCare(data){
  return axios.post(`/care/user`, data);
}

//获取评论列表
export function getComment(data){
  return axios.post(`/comment/listMain`, data);
}

//发布评论
export function commentPublist(data){
  return axios.post(`/comment/publish`, data);
}

//点赞
export function like(data){
  return axios.post(`/like/media`, data);
}

//查询主评论的子评论
export function sonComment(data){
  return axios.post(`/comment/listSub`, data);
}

//分享媒体
export function shareMedia(data){
  return axios.post(`/share/media`, data);
}

//色区帖子列表
export function sequMedia(data){
  return axios.get(`/media/sexArea/list`, {params: data});
}

//用户发布色区图文帖子
export function sexAreaPub(data){
  return axios.post(`/media/sexArea/pub`, data);
}

//色区热门标签
export function sexHotTags(data){
  return axios.get(`/media/sexArea/tags/hot`, {params: data});
}

//图片上传接口
export function sendImg(data){
  return axios.post(`/upload/img`, data, {noEncrpty: true});
}

//视频能否播放检查
export function playCheck(data){
  return axios.post(`/media/verifyPlay`, data);
}

//用户购买视频
export function payVideo(data){
  return axios.post(`/media/buy`, data);
}

//游戏钱包余额接口
export function getBanlance(data){
  return axios.post(`/game/wali/getBalance`, data);
}

//游戏账单记录接口
export function getGameBill(data){
  return axios.post(`/game/wali/translog`, data);
}

//获取获取系统通用配置
export function getConfig(data) {
  return axios.post(`/config/system`, data);
}

//获取游戏钱包的常见问题
export function getQuestion(data){
  return axios.get(`/game/wali/faq`, {params: data});
}

//获取购买金币配置
export function getMoneyList(data){
  return axios.post(`/rchg/buygoldcfg`, data);
}

// 点击游戏列表
export function gameUrl(data) {
  return axios.post(`/game/wali/gameEnter`, data);
}


//图片解密
export const imgDecypt = async url => {
  let encoder = new TextEncoder();
  let KEY = encoder.encode("2019ysapp7527");
  return new Promise(resolve => {
    let oReq = new XMLHttpRequest();
    oReq.open("GET", url, true);
    oReq.responseType = "arraybuffer";
    oReq.onload = function () {
      var arrayBuffer = oReq.response; // 注意:不是oReq.responseText
      if (arrayBuffer) {
        var bytes = new Uint8Array(arrayBuffer);
        for (let i = 0; i < 100; i++) {
          bytes[i] ^= KEY[i % KEY.length]
        }
        const blob = new Blob([bytes], {
          type: "image/png"
        })
        const url = URL.createObjectURL(blob)
        resolve(url)
      }
    };
    oReq.send("");
  }).catch(() => {
    console.log("解密失败");
  })
};

// 查询中奖信息
export function queryGameWinLog(data) {
  return axios.post(`/game/wali/getwinlog`, data);
}

//只有vip视频观看超过一定比例
export function sendValidWatched(mediaId) {
  return axios.post(`/media/validWatched`, {mediaId:mediaId});
}

//埋点
export function addLog(data) {
  let logApi = JSON.parse(sessionStorage.getItem('logUrl'));
  return axios.post(logApi, data);
}
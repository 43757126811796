import store from "@/store/index.js";
import route from "@/router/index.js";
import { adClickReport } from "@/api/index.js";

import { Api } from "@/utils/index.js";

export const AdType = {
  ATP_Invalid: 0, ///  占位符，无意义
  ATP_Launch: 1, /// 启动页
  ATP_YcTurn: 2, /// 点播 (推荐、独家、原创)
  ATP_SpecialTurn: 3, /// 专区轮播
  ATP_Activity: 4, /// 活动弹窗
  ATP_Best: 5, /// 精品页广告
  ATP_Play: 6, /// 播放页面
  avCommentary: 7, /// AV解说
  FuLiZhongXin: 8, /// 福利中心
  goodVideo: 9, ///好片大赏
  seArea: 10, ///色区
  game: 11, ///game
  loufeng: 12, ///楼凤（解锁专区、认证专区、赔付专区、赔付专区、裸聊详情）
  homeRecommend: 13, ///首页推荐
  goodVideoBanner: 14, ///好片大赏轮播
  homeArea: 15, ///首页专区
  bloggerRanking: 16, ///博主榜
  content2x2: 17, //内容2x2
  columnAd: 18, //分栏广告
  appListAd: 19, //应用弹窗
  topListAd: 301, //九宫格横幅广告
  homePopupAdv: 302, //首页弹窗广告
  floatAd: 21,
  freeVideo: 22, // 长视频播放前广告
  homeAdv: 28, // 长视频播放前广告
  videoAdv: 29, // 长视频播放页广告
  onDemandAdv: 30, // 点播播放页广告
};

/**
 * 获取广告
 * @param {*} type  广告类型
 * @returns   广告数据
 */
export function getAdItem(type) {
  let appConfig = JSON.parse(store.state.app.appConfig);
  if (!type || !appConfig || !appConfig.ads || appConfig.ads.length == 0)
    return [];
  let advData = appConfig.ads.filter((item) => {
    if (item.posType == type && item.items) {
      return item.items;
    }
  });
  if (!advData || advData.length == 0) return [];
  return advData[0].items;
}

/**
 * 内部跳转
 * @param {*} url  内部跳转的url
 */
function innerJump(url, type) {
  // console.log(url)
  let urlIndex = url.indexOf("?");
  let uri = url;
  if (urlIndex != -1) {
    uri = url.substring(0, urlIndex);
  }

  const routeMap = {
    main_tab: "/home/introduce", //跳转首页
    // 'game_page_new': '/game', //跳转游戏
    loufeng_page: "/loufeng", //楼风
    page_seba_new: "/seArea", //色吧
    page_vip: "/vipList", //VIP
    page_personal_card: "/promote", //推广
    // 'good_video': '/goodVideo', //好片大赏
  };
  if (url == "page_vip") {
    let vipGrap = {
      type: type || "banner",
    };
    sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap));
  }
  // 跳转博主
  if (uri == "blogger_details_page") {
    let uid = getUrlParam(url.substring(urlIndex), "uid");
    if (!uid) return;
    route.push({
      path: "/bloggerDetails",
      query: {
        id: uid,
      },
    });
    return;
  }
  if (uri == "good_video") {
    let id = getUrlParam(url.substring(urlIndex), "id");
    if (!id) return;
    route.push({
      path: "/goodVideo",
      query: {
        id: id,
      },
    });
    return;
  }
  // 跳转AV解说
  if (uri == "av_commentary_page") {
    route.push({
      path: "/onDemand/exclusive",
      query: {
        type: "av",
      },
    });
    return;
  }

  let routePath = routeMap[uri];
  if (routePath) {
    route.push(routePath);
    return;
  }
}
/**
 * 截取URL参数
 * @param name 需要截取url的参数
 * @returns
 */
function getUrlParam(url, name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = url.substr(1).match(reg); //匹配目标参数

  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}

/**
 * 活动跳转
 * @param {*} item
 */
function Activity(url) {
  let murl = "https://" + url;
  if (murl.indexOf("token") != -1) {
    let token = localStorage.getItem("Authorization");
    murl = murl.replace(/token=/, `token=${token}`);
  }
  if (murl.indexOf("apihost") != -1) {
    let baseUrl = sessionStorage.getItem("baseUrl").replace(/\/api\/h5\//, "");
    murl = murl.replace(/apihost=/, `apihost=${baseUrl}`);
  }
  location = murl;
}

/**
 * 跳转广告
 * @param {*} item 广告信息
 */
export function jumpAdv(item, type) {
  let id = item.id;
  let name = item.name;

  try {
    if (id) {
      Api(adClickReport, {
        adId: id,
        adName: name,
      });
    }
  } catch (e) {
    console.log(e);
  }
  let url = item.jumpLink || item.jumpTo || item.href;
  let index = url.indexOf("yinseinner");
  let yinssIndex = url.indexOf("yinselinks");
  if (index != -1) {
    innerJump(url.substring(13), type);
    return;
  }
  if (yinssIndex != -1) {
    Activity(url.substring(13));
    return;
  }

  setTimeout(() => {
    // alert("xxxxcxxxcxcxcx")
    window.open(url, "_blank");
  });
  // setTimeout(() => window.open(url, "_blank"))
  // let a = document.createElement("a");
  // document.body.appendChild(a);
  // a.style = "display: none";
  // a.href = url;
  // a.target="view_window"
  // a.click();
  // document.body.removeChild(a);
  // location = url
}

<template>
  <div class="noData">
    <div class="svgBox">
      <svg-icon iconClass="noData" class="noData_svg"></svg-icon>
      <p class="desc">{{description}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: "这里空空如也～",
    },
    heightc: {
      type: String,
    },
  },
};
</script>

<style lang='scss' scoped>
.noData {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  color: #aba9a9;
  .svgBox {
    margin-bottom: 30px;
    .noData_svg {
      width: 120px;
      height: 100px;
    }
    .desc {
      padding-bottom: 50px;
    }
  }
}
</style>
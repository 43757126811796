<template>
  <transition name="shareShow">
    <div class="content" v-if="words.length>0||img.length>0">
      <div class="content-box">
        <div class="top">
          <div class="words words-img" v-for="(item,index) in img" :key="index+'1'">
            <ImgDecypt class="tag-img" :src="item.imgUrl||item.pictureUrl" @click.native="goImg" />
          </div>
          <div class="words" v-for="(item,index) in words" :key="index">
            <div class="item-top">
              <div class="title">{{item.title}}</div>
              <div class="words-content" v-html="item.content"></div>
            </div>
            <div class="item-bottom" @click="go">确定</div>
          </div>
        </div>
        <div class="bottom" @click="close()"></div>
      </div>
    </div>
  </transition>
</template>
<script>
import { jumpAdv } from "@/utils/getAdv.js";
export default {
  props: {
    words: {
      type: Array,
      default: () => {
        return [];
      },
    },
    img: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    close() {
      if (this.words.length > 0) {
        this.words.pop();
      } else {
        this.img.pop();
      }
    },
    go() {
      if (this.words.length > 0) {
        let p = this.words.pop();
        jumpAdv(p);
      }
    },
    goImg() {
      if (this.img.length > 0) {
        let p = this.img.pop();
        jumpAdv(p);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  position: fixed;
  z-index: 12;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.4);
}
.content-box {
  position: absolute;
  bottom: 0;
  height: 400px;
  width: 300px;
  left: 50%;
  margin-left: -150px;
  top: 50%;
  margin-top: -200px;
  box-sizing: border-box;
}
.top {
  position: relative;
  height: 390px;
}
.words {
  position: absolute;
  width: 280px;
  background: #fff;
  border-radius: 4px;
  padding: 15px;
  height: 373.5px;
  z-index: 2;
  // box-sizing: border-box;
}
.words-img {
  background: none;
}
.words-img /deep/ .vanImage {
  background: none;
}
.tag-img {
  width: 100%;
  height: 100%;
}
.title {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}
.words-content {
  height: 220px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 20px;
  line-height: 16px;
  letter-spacing: 2px;
}
.words-content /deep/ a {
  text-decoration: underline;
  color: #4291df;
}
.item-bottom {
  width: 130px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background: $vermillion;
  margin: 10px auto 0;
  font-size: 14px;
}
.bottom {
  width: 34px;
  height: 34px;
  background: url("../../assets/png/ann_close.png") center center no-repeat;
  background-size: 100%;
  margin: 20px auto 0;
}
</style>
const state = {
  //热门视频信息
  videoObj: {
    index: 0,
    list: [],
  },
  //关注视频信息
  videoObjCar: {
    index: 0,
    list: [],
  },
  //社区视频播放器
  communityObj: {
    index: 0, //索引
    list: [], //列表
    isRecommend: true, //当前list数据请求完，是否请求推荐数据
  },
  videoRoadLine: sessionStorage.getItem("videoRoadLine"), // 选择的线路
};
const mutations = {
  //保存社区信息列表
  SET_COMMUNITY_LIST: (state, { list = [], isRecommend = true }) => {
    state.communityObj.list = list;
    state.communityObj.isRecommend = isRecommend;
  },
  //修改社区播放器索引
  UPDATE_COMMUNITY_INDEX: (state, index) => {
    state.communityObj.index = index;
  },
  //从列表头开始删除数量
  DELETE_COMMUNITY_LIST: (state, { index }) => {
    state.communityObj.list.splice(0, index);
  },
  //更改当前关注博主的所有视频设置为关注
  UPDATE_ALL_COMMUNITY_FOCUS: (state, uid) => {
    let list = state.communityObj.list;
    for (let i = 0; i < list.length; i++) {
      if (list[i].author.id == uid) {
        list[i].author.cared = true;
      }
    }
  },
  //更改当前关注博主的所有视频设置为取消关注
  UPDATE_ALL_COMMUNITY_UNFOCUS: (state, uid) => {
    let list = state.communityObj.list;
    for (let i = 0; i < list.length; i++) {
      if (list[i].author.id == uid) {
        list[i].author.cared = false;
      }
    }
  },
  // 修改指定索引的视频信息
  UPDATE_INDEX_COMMUNITY_VIDEOINFO: (state, { videoInfo, index }) => {
    state.communityObj.list[index] = videoInfo;
  },
  /**------------------------------------------------短视频---------------------------------------------*/
  //保存视频信息列表
  SET_VIDEOLIST: (state, { list, isFocus = false }) => {
    //关注视频
    if (isFocus) {
      state.videoObjCar.list = list;
    } else {
      //热门视频
      state.videoObj.list = list;
    }
  },
  //修改当前播放视频索引
  UPDATE_VIDEOLIST_INDEX: (state, { index, isFocus = false }) => {
    //关注视频
    if (isFocus) {
      state.videoObjCar.index = index;
    } else {
      //热门视频
      state.videoObj.index = index;
    }
  },
  //从列表头开始删除数量
  DELETE_VIDEOLIST: (state, { index, isFocus = false }) => {
    //关注视频
    if (isFocus) {
      state.videoObjCar.list.splice(0, index);
    } else {
      //热门视频
      state.videoObj.list.splice(0, index);
    }
  },
  // 修改指定索引的视频信息
  UPDATE_INDEX_VIDEOINFO: (state, { videoInfo, index, isFocus = false }) => {
    //关注视频
    if (isFocus) {
      state.videoObjCar.list[index] = videoInfo;
    } else {
      //热门视频
      state.videoObj.list[index] = videoInfo;
    }
  },
  //更改当前关注博主的所有视频设置为关注
  UPDATE_ALL_FOCUS: (state, uid) => {
    let list = state.videoObj?.list;
    for (let i = 0; i < list.length; i++) {
      if (list[i].author) {
        if (list[i].author.id == uid) {
          list[i].author.cared = true;
        }
      }
    }
  },
  //更改当前关注博主的所有视频设置为取消关注
  UPDATE_ALL_UNFOCUS: (state, uid) => {
    let list = state.videoObj?.list;
    for (let i = 0; i < list.length; i++) {
      if (list[i].author) {
        if (list[i].author.id == uid) {
          list[i].author.cared = false;
        }
      }
    }
  },
  // 设置线路信息
  SET_VIDEOROADLINE: (state, roadLine) => {
    sessionStorage.setItem("videoRoadLine", JSON.stringify(roadLine));
    state.videoRoadLine = roadLine;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

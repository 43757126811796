<template>
  <div id="pcm-app">
    <!-- <transition :name="transitionName" appear> -->
    <keep-alive :include="keepAliveList">
      <router-view class="all-content" />
    </keep-alive>
    <!-- </transition> -->
    <!-- loading -->
    <van-overlay :show="isLoading" class="loadingBox flex-center">
      <van-loading type="spinner" color="#f21313" size="24px" vertical></van-loading>
    </van-overlay>
    <div></div>
    <!-- <VueDragResize :isActive="true" :isResizable="false" class="advBox" :z="10" :x="advx" :y="advy" v-if="appAdvList.length && showAdv" :key="advy">
      <div @click="jumpPage">
        <ImgDecypt class="floatAdv" :src="appAdvList[0].pictureUrl"  />
      </div>
      <div class="adClose" @click="closeAd">
        <svg-icon icon-class="close1"/>
      </div>
    </VueDragResize> -->
  </div>
</template>
<script>
// import VueDragResize from 'vue-drag-resize';
// import { jumpAdv } from "@/utils/getAdv.js";
// import { getAdItem, AdType } from "@/utils/getAdv.js";
import { setScrollTop } from "@/utils/index.js";
import { openDB, getDataByKey } from "@/utils/indexedDB.js";
import { mapGetters } from "vuex";
import { getDevType } from '@/utils';
import inobounce from "@/assets/js/inobounce.min.js";
export default {
  components: {
    // VueDragResize
  },
  data() {
    return {
      isLoading: false,
      // transitionName: "",
      include: ["mainkeep", "bloggerDetails"], //需要缓存的页面
      //需要缓存的滚动条
      keepPath: {
        "/onDemand/exclusive": {
          className: "av_commentary",
        },
        "/onDemand/recommend": {
          className: "recommend-warp",
        },
        "/onDemand/original": {
          className: "originalPage",
        },
        "/seArea": {
          className: "section",
        },
        "/loufeng": {
          className: "unlockZone",
        },
        "/home/introduce": {
          className: "scroll-content",
        },
        "/home/tag": {
          className: "scroll-content",
        },
        "/home/rank": {
          className: "scroll-content",
        },
        "/bloggerDetails": {
          className: "blogger_detalis",
        },
        "/moreVideo": {
          className: "section",
        },
        "/videoLibrary": {
          className: "main",
        },
      },
      // advx: 0,
      // advy: 0,
      // appAdvList: [],
      // showAdv: true,
    };
  },
  computed: {
    ...mapGetters(["keepAliveList"]),
  },
  watch: {
    async $route(to, from) {

      // 保存滚动条
      if (this.keepPath[from.path]) {
        let sonPath = from.meta.sonPath;
        let className = this.keepPath[from.path]?.className;
        // 查找到className才储存
        if (className) {
          let domArr = document.getElementsByClassName(className);
          let domIndex = className == 'originalPage' ? domArr.length - 1 : 0;
          let dom = domArr[domIndex];

          let scrollTop = dom?.scrollTop;
          if (dom) {
            //如果有子级就把子级储存起来
            if (sonPath) {
              setScrollTop(className, from.path, sonPath, scrollTop);
            } else {
              setScrollTop(className, from.path, undefined, scrollTop);
            }
          }
        }
        
      }

      // 设置滚动条
      if (this.keepPath[to.path]) {
        let path = to.path;
        openDB("sis_db", 1).then(async (db) => {
          let pathData = await getDataByKey(db, "scrollBar", path);
          if (pathData && pathData.path == path) {
            if (pathData.sonPath) {
              if (pathData.sonPath == to.meta.sonPath) {
                let domArr = document.getElementsByClassName(pathData.className);
                let domIndex = pathData.className == 'originalPage' ? domArr.length - 1 : 0;
                let dom = domArr[domIndex];
                if (dom) {
                  dom.scrollTop = pathData.scrollTop;
                }
              }
            } else {
              let domArr = document.getElementsByClassName(pathData.className);
              let domIndex = pathData.className == 'originalPage' ? domArr.length - 1 : 0;
              let dom = domArr[domIndex];
              if (dom) {
                dom.scrollTop = pathData.scrollTop;
              }
            }
          }
        });
      }

      // 避免首页滑动影响游戏滑动
      if (to.name == "game") {
        let dom = document.querySelector(".scroll-content");
        if (dom) {
          dom.scrollTop = 0;
        }
      }

      // if (to.meta.index > from.meta.index) {
      //   this.transitionName = "slide-left";
      // } else if (to.meta.index < from.meta.index) {
      //   this.transitionName = "slide-right";
      // } else {
      //   this.transitionName = "";
      // }

      // console.log(to.path.indexOf('home'))
      // let toPath = to.path
      // if (toPath.indexOf('home') > -1) {
      //   let w = window.innerWidth;
      //   let h = window.innerHeight;
      //   this.advx = w * 0.7;
      //   this.advy = h * 0.7;
      //   this.showAdv = true;
      //   // console.log(this.advy)
      // } else if (toPath == '/shortVideo') {
        
      //   let w = window.innerWidth;
      //   let h = window.innerHeight;
      //   this.advx = w * 0.03;
      //   this.advy = h * 0.03;
      //   this.showAdv = true;
      //   // console.log(this.advy)
      // } else {
      //   this.showAdv = false;
      // }
    },
    
  },
  created() {
    // let w = window.innerWidth;
    // let h = window.innerHeight;
    // this.advx = w * 0.7;
    // this.advy = h * 0.7;
    this.$store.watch((state, getters) => {
      this.isLoading = getters.isLoading; // 特权卡充值显隐
    });
    // this.appAdvList = getAdItem(AdType.floatAd);
    // console.log(this.appAdvList)
  },
  mounted() {
    //google 不支持此方法，加了后页面所以滚动将被禁止
    if (
      getDevType() == "ios" &&
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      inobounce.enable();
    }
  },
  methods: {
    // closeAd() {
    //   this.showAdv = false;
    //   // console.log(123123)
    // },
    // jumpPage() {
    //   jumpAdv(this.appAdvList[0]);
    // }
  }
};
</script>
<style lang='scss'>
.van-loading--spinner {
  text-align: center;
  margin-top: 80px;
}
.loadingBox {
  z-index: 99999 !important;
  .van-loading--spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
  }
}
// .advBox {
//   width: 100px;
//   height: 100px;
//   // background: blue;
//   outline: none !important;
//   .content-container {
//     width: 100px !important;
//     height: 100px !important;
//   }
  
// }
// .vdr.active:before{
//   outline: none !important;
//   display: none !important;
// }
// .active::after {
//   display: none !important;
// }

</style>
<style  lang="scss">
/* 淡隐淡现 */
.shareShow-enter-active,
.shareShow-leave-active {
  transition: all 0.3s linear;
}

.shareShow-enter-to,
.shareShow-leave {
  opacity: 1;
}

.shareShow-leave-to,
.shareShow-enter {
  opacity: 0;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100% 0);
}

.all-content {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
  top: 0;
}

#pcm-app {
  width: 100%;
  max-width: 540px;
  margin: auto;
  position: relative;
  background-color: #fff;
  height: 100%;
  min-height: 100%;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .van-tabbar--fixed {
    position: fixed;
    bottom: 0;
    left: auto;
    max-width: 540px;
  }
}
//全局设置下拉框的最大宽度
.van-dropdown-item--down {
  width: 100%;
  max-width: 540px;
  margin: auto;
}

.adClose {
  position: absolute;
  top: -0.3rem;
  right: -0.2rem;
  font-size: 0.6rem;
}

</style>

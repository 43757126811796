<template>
  <div class="warp adv" :class="{ warpNo: !isShowIndicators }">
    <swiper ref="swiper" :options="swiperOption" v-if="advList.length > 0">
      <swiper-slide
        class="swiperSlide"
        v-for="(item, index) in advList"
        :key="index"
        @click.native="jump(item)"
      >
        <ImgDecypt :src="item.pictureUrl" />
        <!-- <img :src="place" class="advimg" :key="index" /> -->
      </swiper-slide>
      <div
        class="swiper-pagination"
        slot="pagination"
        v-if="advList.length != 1"
      ></div>
    </swiper>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { jumpAdv } from "@/utils/getAdv.js";
// import { imgDecypt } from "@/api/app.js";
import { mapGetters } from "vuex";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    advList: {
      type: Array,
      require: true,
      default() {
        return [];
      },
    },
    isShowIndicators: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      logicCfg: "logicCfg",
    }),
  },
  data() {
    return {
      place: require("@/assets/png/loding2.png"),
      // 轮播图配置
      swiperOption: {
        loop: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "my-bullet-active",
        },
        allowTouchMove: this.advList.length > 1 ? true : false,
        autoplay:
          this.advList.length > 1
            ? {
                disableOnInteraction: false,
                delay: 5000,
              }
            : false,
      },
    };
  },
  mounted() {
    // console.log(111);
    //   会员卡图片解密
    // let imgDomain = this.logicCfg.imgDomain;
    // if (imgDomain.slice(-1) != "/") {
    //   imgDomain = imgDomain + "/";
    // }
    // let dom = document.querySelectorAll(".adv .swiperSlide");
    // // let datalen = this.advList.length;
    // let domlen = dom.length;
    // for (let i = 0; i < domlen; i++) {
    //   // let slideIndex = i % datalen;
    //   let imgDom = dom[i].querySelector("img");
    //   imgDecypt(
    //     imgDomain +
    //       this.advList[dom[i].getAttribute("data-swiper-slide-index")]
    //         ?.pictureUrl
    //   ).then((url) => {
    //     if (imgDom) {
    //       imgDom.src = url;
    //     }
    //   });
    // }
  },
  methods: {
    // 跳转广告
    jump(item) {
      jumpAdv(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.advimg {
  width: 100%;
  height: 100%;
  background: #dcdee0;
}
.warp {
  /deep/ .swiper-pagination {
    display: block;
    bottom: 6px;
    width: 100%;
    text-align: center;
    z-index: 999;
  }
  /deep/ .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 2px;
    background: #fff;
    opacity: 1;
  }
  /deep/ .my-bullet-active {
    background: #fff;
    opacity: 1;
    width: 30px;
    border-radius: 25px;
  }
  .swiperSlide {
    height: 150px;
    width: 100%;
  }
}
.warpNo {
  /deep/ .swiper-pagination {
    display: none !important;
  }
}
</style>

import axios from "@/utils/request";

//游客登陆
export function loginGuest(data) {
  return axios.post(`/login/guest`, data);
  // return axios.get(`/media/sexArea/list`, data);
}

//获取用户信息
export function getUserInfo(data){
  return axios.post(`/user/info`, data);
}

//手机校验
export function mobileverify(data){
  return axios.post(`/login/resetpassword/accverify`, data);
}

//登录
export function login(data){
  // return axios.post(`/login/h5`, data);
  return axios.post(`/login/official`, data);
}

//发送验证码
export function captcha(data){
  return axios.post(`/login/captcha`, data);
}

//重置密码
export function resetpassword(data){
  return axios.post(`/login/resetpassword`, data);
}

//注册 / 绑定账号
export function register(data){
  return axios.post(`/login/register`, data);
}

// 获取用户钱包信息
export function queryWallet() {
  return axios.post(`/user/wallet/info`)
}

// 修改用户信息
export function modifyUserInfo(data) {
  return axios.post(`/user/info/modify`, data)
}

// 上传头像、背景
export function editAvatar(data) {
  return axios.post(`/user/avatar/modify`, data)
}

// 绑定邀请码
export function bindInvitaCode(data) {
  return axios.post(`/invite/do`, data)
}

// 兑换码兑换
export function useRedeemCode(data) {
  return axios.post(`/actcode/use`, data)
}

// 查询提现金额区间
// export function queryWithdrawRange(data) {
//   return axios.post(`/withdraw/range`, data)
// }

// 查询提现金额区间
export function queryWithdrawMethod(data) {
  return axios.post(`/withdraw/method`, data)
}

// 提现
export function withdraw(data) {
  return axios.post(`/withdraw/submit`, data)
}

// 绑定银行卡
export function bindBankCard(data) {
  return axios.post(`/user/bank/bind`, data)
}
// 查询银行卡
export function queryBankCard(data) {
  return axios.post(`/user/bank/info`, data)
}
// 修改银行卡
export function modifyBankCard(data) {
  return axios.post(`/user/bank/modify`, data)
}

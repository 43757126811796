import axios from "axios";
import store from "@/store/index.js"
import CryptoJS from 'crypto-js'
import route from "@/router/index.js"
import hmac from 'js-crypto-hmac';
import {
  getDevType
} from "@/utils/index.js"
let getToken = () => {
  return localStorage.getItem("Authorization");
};
let baseUrl = () => {
  return store.getters.baseUrl;
};
let DevID = () => {
  return store.getters.Uid;
};

const isEncrpty = 1; //是否开启参数加密 1 开启 0 关闭

let baseUrlArr = JSON.parse(process.env.VUE_APP_BASE_HOSTS);
let baseLen = baseUrlArr.length;
let randNum = parseInt(Math.random() * (baseLen + 1), 10);
// create an axios instance
const service = axios.create({
  baseURL: baseUrlArr[randNum] + process.env.VUE_APP_BASE_PATH, // api的base_url
  timeout: 1000 * 30, // request timeout
});

// request interceptor
service.interceptors.request.use(
  async (config) => {
      let logApi = sessionStorage.getItem('logUrl') != "undefined" ? JSON.parse(sessionStorage.getItem('logUrl')) : undefined;
      //接口参数加密
      if (isEncrpty === 1 && !config.noEncrpty && config.url != logApi) {
        if (config.method == 'get') {
          let data = config.params;
          //如果get请求带的有参数
          if (data) {
            //把所有参数改为字符串
            for (let key in data) {
              if (data[key] != null && data[key] != undefined && typeof data[key] != "object") {
                data[key] = String(data[key]);
              } else {
                delete data[key];
              }
            }
            config.params = {
              data: encrypt(data)
            }
          }

        } else {
          if (config.data) {
            config.data = {
              data: encrypt(config.data)
            }
          }
        }
      }
      config.headers["Authorization"] = getToken() ? getToken() : "";
      config.headers["content-type"] = "application/json";
      if (config.url != logApi) {
        config.headers["x-api-key"] = await sign(config.url);
      }
      config.headers["x-user-agent"] = `SysType=${getDevType()};Ver=2.2.5;BuildID=yinse_sis_opera;DevID=${DevID()};DevType=iPhone;Terminal=${isEncrpty}`;
      if (baseUrl()) {
        config.baseURL = baseUrl();
      }
      return config;
    },
    (error) => {
      // Do something with request error
      // console.log(error); // for debug
      Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.status == 200 && response.data.hash) {
      response.data.data = JSON.parse(cryptoDecrypt(response.data.data))
    }
    if (response.status === 200 && response.data.code === 200) {
      return response;
    }
    if (response.status === 200) {
      return response;
    }
    return Promise.reject({
      code: -1,
    });
  },
  async (error) => {
    // token 失效重新获取token
    if ((error?.response && error?.response?.status === 400 && error?.response?.data && error?.response?.data?.code == 1009) || error?.response?.data?.code == 1004) {
      localStorage.removeItem('Authorization');
      route.push({
        path: '/login'
      });
      // let req = {
      //   devType: 2,
      //   devID: store.getters.Uid,
      // };
      // let ret = await Api(loginGuest, req);
      // if (ret && ret.code == 200) {
      //   localStorage.setItem("Authorization", ret.data.token);
      //   route.go(0)
      //   return;
      // }
      return Promise.reject(error?.response?.data);
    }
    if (error?.response && error?.response?.status === 400) {
      return Promise.reject(error.response.data)

    }
    if (error?.response && error?.response?.status === 500) {
      return Promise.reject(error.response.data);
    }
  });

// 生成签名
async function sign(path) {
  let signObj = {};
  let keyStr = "fasd98xasASADd6Q";
  let signTime = sessionStorage.getItem("_key_server_time");
  let token = getToken()
  let nowTime = Date.now();
  let timestamp = parseInt((nowTime - Number(signTime)) / 1000);
  signObj['nonce'] = getRandom(6);
  signObj['path'] = "/api" + path;
  signObj['timestamp'] = timestamp.toString();
  signObj['token'] = token || "";
  signObj['userAgent'] = navigator.userAgent;
  let bytes = new TextEncoder('utf-8').encode(JSON.stringify(signObj));
  let key = new TextEncoder('utf-8').encode(keyStr);
  let sha1Encrypt = await hmac.compute(key, bytes, "SHA-1");
  return `timestamp=${timestamp};sign=${Bytes2HexString(sha1Encrypt)};nonce=${signObj['nonce']}`;

}

//生成随机数
function getRandom(numSize) {
  let alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (var i = 0; i < numSize; i++) {
    result = result + alphabet[Math.round(Math.random() * (alphabet.length - 1))];
  }
  return result;
}


//参数加密
function encrypt(prop) {
  // 处理word参数 1.把请求字段和值转成json字符串
  let word = JSON.stringify(prop); //参数转成json字符串
  let eKey = "pa42en68iszeygvy"; //公钥加密后生成的私钥
  let iv = 'pa42en68iszeygvy';
  iv = CryptoJS.enc.Utf8.parse(iv);
  let key = CryptoJS.enc.Utf8.parse(eKey);
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC, //模式
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

//接口解密
function cryptoDecrypt(data) {
  let keyStr = "dsf12SZ32f"; //密钥
  let dataStr = (data + "").replace(/\n*$/g, "").replace(/\n/g, "");
  let bytes = Buffer.from(dataStr, "base64");
  let keyTail = fromCharCodes(bytes.slice(-6));
  let key = CryptoJS.enc.Utf8.parse(keyStr + keyTail);
  let iv = CryptoJS.enc.Utf8.parse(keyStr + keyTail);
  let dst = bytes.slice(0, bytes.length - 6);
  let hexData = Bytes2HexString(dst);
  let base64Data = CryptoJS.enc.Hex.parse(hexData).toString(CryptoJS.enc.Base64);
  let decrypt = CryptoJS.AES.decrypt(base64Data, key, {
    mode: CryptoJS.mode.CBC,
    iv: iv
  }).toString(CryptoJS.enc.Utf8)
  return decrypt
}

// 将unicode 编码转位字符
function fromCharCodes(arrBytes) {
  let str = "";
  for (let i = 0; i < arrBytes.length; i++) {
    str += String.fromCharCode(arrBytes[i])
  }
  return str;
}

//二进制数据转hex
function Bytes2HexString(arrBytes) {
  var str = "";
  for (var i = 0; i < arrBytes.length; i++) {
    var tmp;
    var num = arrBytes[i];
    if (num < 0) {
      //此处填坑，当byte因为符合位导致数值为负时候，需要对数据进行处理
      tmp = (255 + num + 1).toString(16);
    } else {
      tmp = num.toString(16);
    }
    if (tmp.length == 1) {
      tmp = "0" + tmp;
    }
    str += tmp;
  }
  return str;
}


export default service;

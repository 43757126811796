import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import "normalize.css/normalize.css";
import "./assets/css/common.css"; //公共的css样式
import './assets/css/reset.css' //样式初始化
import VueTouch from 'vue-touch' //左右滑动手势插件
import * as filters from './assets/js/filter'
import Vant, { Lazyload } from "vant";
import "vant/lib/index.css";
import {
  Api
} from "@/utils/index.js";
import "@/icons";
import './permission'
import VueClipboard from 'vue-clipboard2' // 点击复制
import placeholder from './assets/png/placeholder.png'; //预加载默认图片
import ImgDecypt from "@/components/ImgDecypt/index.vue";
import NoData from '@/components/NoData'; //暂无数据注册成全局组件
import {
  Toast
} from 'vant'; //公告提示弹窗全局引入
import autoMail from 'vue-auto-mail'
Vue.directive('autoMail', autoMail)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]) //插入过滤器名和对应方法
})

Vue.prototype.$bus = new Vue() //全局事件总线eventbus
Vue.component("nodata", NoData) //暂无数据注册成全局组件
Vue.component("ImgDecypt", ImgDecypt) //注册全局图片解密组件
Vue.prototype.holder = placeholder;

Vue.use(Lazyload, {
  lazyComponent: true,
});

Vue.use(Vant);
Vue.use(VueClipboard)
Vue.use(Toast);

Vue.use(VueTouch, {
  name: 'v-touch'
});
Vue.prototype.$Api = Api;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

const state = {
  Uid: localStorage.getItem("Uid"),
  appConfig: sessionStorage.getItem("appConfig"),
  baseUrl: sessionStorage.getItem("baseUrl"), //api域名
  isLoading: false,
  keepAliveList: [], //需要缓存的页面
}

const mutations = {
  SET_UID: (state, murmur) => {
    state.Uid = murmur;
  },
  SET_APPCONFIG: (state, appConfig) => {
    state.appConfig = appConfig;
  },
  SET_REQUESTURL: (state, baseUrl) => {
    state.baseUrl = baseUrl;
  },
  SET_LODING: (state, status) => {
    state.isLoading = status;
  },
  SET_KEEP: (state, value) => {
    state.keepAliveList = value
  }
}

const actions = {
  //保存全局loading配置
  GET_LOADING({
    commit
  }, status) {
    commit("SET_LODING", status)
  },
  //保存选线成功后的域名
  GET_REQUESTURL({
    commit
  }, baseUrl) {
    commit("SET_REQUESTURL", baseUrl)
  },
  //保存全局配置文件
  GET_APPCONFIG({
    commit
  }, appConfig) {
    sessionStorage.setItem("appConfig", appConfig)
    commit("SET_APPCONFIG", appConfig)
  },
  // 设置缓存滚动条页面
  SET_KEEPALIVE({
    commit
  }, value) {
    commit("SET_KEEP", value)
  },
  //生成唯一id
  GET_UID({
    commit
  }) {
    let len = 16;
    let radix = 16;
    var chars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
        ""
      );
    var uuid = [],
      i;
    radix = radix || chars.length;

    if (len) {
      // Compact form
      for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
    } else {
      // rfc4122, version 4 form
      var r;

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
      uuid[14] = "4";

      // Fill in random data. At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16);
          uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    let uid = uuid.join("") + Date.now();
    localStorage.setItem("Uid", uid)
    commit("SET_UID", uid)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
<template>
  <div id="app">
    <div class="scroll-content" @scroll="scrollknow">
      <keep-alive :include="mainViewKeepList">
        <router-view class="content" />
      </keep-alive>
    </div>
    <Tabbar></Tabbar>

    <wordsann :words="words" :img="img" />
    <!-- <div class="backTop" v-if="btnFlag" @click="backTop"></div> -->
    <!-- 首页弹窗广告 -->
    <div class="appPop" v-if="homePopShow">
      <div v-for="(item, index) in homeAdvList" :key="item.id">
        <div class="appPopContent">
          <div class="imgBox">
            <ImgDecypt
              :src="item.pictureUrl"
              :key="item.id"
              @click.native="jumpAdd(item)"
              class="imgBox"
            />
          </div>
          <div class="appListClose" @click="close(index)"></div>
        </div>
      </div>
    </div>
    <!-- 九宫格弹窗广告 -->
    <div class="appPop" v-if="showAppPop">
      <div class="appPopContent">
        <!-- 广告轮播图 -->
        <!--        <AdvSwiper-->
        <!--          :isShowIndicators="topAdvList.length != 1"-->
        <!--          class="topAdv"-->
        <!--          :advList="topAdvList"-->
        <!--          v-if="topAdvList.length > 0"-->
        <!--        ></AdvSwiper>-->
        <div class="appList">
          <div
            class="appItem"
            v-for="(item, index) in appAdvList.slice(0, 16)"
            :key="item.id"
            @click="jumpAdd(item)"
          >
            <div>
              <ImgDecypt
                class="appImage"
                :src="item.pictureUrl"
                :key="item.pictureUrl"
              />
              <div class="appDownload">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="appListClose" @click="showAppPop = false"></div>
      </div>
    </div>

    <!-- 创建圈子popup -->
    <van-popup
      class="newPopup"
      v-model="newShow"
      round
      position="bottom"
      :style="{ height: '150px' }"
    >
      <div class="content">
        <div
          class="newBtn"
          @click="
            newShow = false;
            $router.push('/newCircle');
          "
        >
          <span>创建圈子</span>
        </div>
        <div class="line"></div>
        <div class="cancelBtn" @click="newShow = false">
          <span>取消</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Tabbar from "@/views/widget/Tabbar.vue";
import wordsann from "@/views/widget/wordsAnn.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import { jumpAdv } from "@/utils/getAdv.js";
import AdvSwiper from "@/components/AdvSwiper/index.vue";

import { mapGetters } from "vuex";
export default {
  name: "mainkeep",
  data() {
    return {
      include: [], //需要缓存的页面
      words: [], //文字公告
      img: [], //图片公告
      appAdvList: [],
      topAdvList: [],
      homeAdvList: [], // 首页弹窗广告
      showAppPop: false,
      newShow: false,
      homePopShow: false, // 显示首页弹窗广告
      ann: JSON.parse(sessionStorage.getItem("appConfig")).blts, //公告
    };
  },
  computed: {
    ...mapGetters(["mainViewKeepList"]),
  },
  components: {
    Tabbar,
    wordsann,
    AdvSwiper,
  },
  created() {
    this.$bus.$on("openNewPopup", () => {
      this.newShow = true;
    });
    if (!sessionStorage.getItem("firstTime")) {
      this.appAdvList = getAdItem(AdType.appListAd);
      this.topAdvList = getAdItem(AdType.topListAd);
      this.homeAdvList = getAdItem(AdType.homePopupAdv) || [];
      this.homeAdvList = this.homeAdvList.reverse();
      if (this.appAdvList.length > 0) {
        this.showAppPop = true;
      }
      if (this.homeAdvList.length > 0) {
        this.homePopShow = true;
      }
      //第一次进入
      sessionStorage.setItem("firstTime", "yes");
      let activitys = this.activity?.filter((item) => item.posType == 4);
      if (activitys?.length > 0) {
        this.img = this.img.concat(activitys[0].items);
      }
      let webPattern =
        /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/g;
      this.words = this.ann
        ?.filter((item) => {
          return item.type == 0;
        })
        .reverse(); //文字公告
      this.img = this.ann
        ?.filter((item) => {
          return item.type == 1;
        })
        .concat(this.img)
        .reverse(); //图片公告
      this.words.forEach((item) => {
        let url = item.content.match(webPattern)[0];
        item.content = item.content.replace(/\n/g, "<br/>");
        item.content = item.content.replace(
          webPattern,
          `<a href="https://${url}">${url}</a>`
        );

        item.content = item.content.replace(
          `https://<a href="https://${url}">${url}</a>/pfqipai`,
          `<a href="https://ptcc.in/pfqipai">https://ptcc.in/pfqipai</a>`
        );
        item.content = item.content.replace(
          `https://<a href="https://${url}">${url}</a>/ruoer`,
          `<a href="https://ptcc.in/ruoer">https://ptcc.in/ruoer</a>`
        );
      });
    }
  },
  methods: {
    jumpAdd(item) {
      jumpAdv(item);
    },
    close(index) {
      if (index == 0) {
        this.homePopShow = false;
      }
      this.homeAdvList.pop();
    },
    scrollknow() {
      // if (this.$route.path.indexOf("home") > -1) {
      //   let dom = e.target;
      //   let tab = document.getElementsByClassName("tab")[0];
      //   let bottomAll = document.getElementsByClassName("bottom-all")[0];
      //   if (dom.scrollTop >= 54) {
      //     tab.style.position = "fixed";
      //     tab.style.top = 0;
      //     bottomAll.style.paddingTop = 39 + "px";
      //   } else {
      //     tab.style.position = "static";
      //     bottomAll.style.paddingTop = 0 + "px";
      //   }
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
}

.scroll-content {
  height: calc(100% - 50px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.content {
  height: auto;
}

.appPop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 15;
  padding: 0 28px;
  box-sizing: border-box;
}

.appPop .jumpOver {
  position: absolute;
  right: 32px;
  top: 47px;
  font-size: 18px;
  color: #ff810d;
  z-index: 11;
}
.appPop .appList {
  // max-height: calc(100% - 90px);
  // overflow-y: auto;
  // margin-top: 90px;
  /* margin-top: 2.88rem; */
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 18px;
  grid-column-gap: 12px;
  padding: 0 33px;
}
.appPop .appItem {
}
.appPop .appItem .appImage {
  width: 68px;
  height: 68px;
  /* background: #00000000; */
  /* border-radius: 0.18rem !important; */
}
.appPop .appItem .appImage /deep/ .vanImage {
  background: #00000000 !important;
}
.appPop .appItem .appImage /deep/ img {
  object-fit: fill !important;
  border-radius: 9px;
}
.appPop .appDownload {
  width: 68px;
  //width: 89px;
  //height: 26px;
  //line-height: 26px;
  //border-radius: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  //background: #f4454d;
  text-align: center;
  margin: 12px auto 0;
  color: #ffff;
}
.appPopContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.imgBox {
  height: 400px;
  width: 300px;
}
.topAdv {
  width: 329px;
  border-radius: 4px;
  overflow: hidden;
  margin: 20px auto;
  box-sizing: border-box;
  /deep/ .swiper-container {
    height: 88px;
  }
  /deep/ img {
    height: 100%;
    object-fit: fill;
  }
}
/deep/ .warp .swiperSlide {
  height: 88px !important;
}
.appListClose {
  width: 38px;
  height: 38px;
  background: url("../assets/png/close4.png");
  background-size: 100% 100%;
  margin: 40px auto 0;
}

.newPopup {
  text-align: center;

  .content {
    font-size: 16px;
    color: #262424;

    .newBtn {
      padding: 18px 0 20px 0;
    }

    .line {
      width: 100%;
      height: 8px;
      background: #f2f2f2;
    }

    .cancelBtn {
      padding: 20px 0;
    }
  }
}
</style>

import router from './router'
import store from "./store"


//全局路由导航
router.beforeEach((to, from, next) => {
    // const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    const appConfigStr = sessionStorage.getItem("appConfig")
    const baseUrl = sessionStorage.getItem("baseUrl")
    // const token = localStorage.getItem('Authorization');
    if ((!appConfigStr || appConfigStr.length == 0 || !baseUrl || baseUrl.length == 0) && to.path != "/") {
        router.push({
            path: '/'
        })
        return;
    }
    if (
      (to.name == "loufeng" && from.name == "loufengLocation" && Object.keys(to.params).length != 0) ||
      (from.name == "loufengDetail" && to.name == "brokerInfo") ||
      (from.name != "videoInfo" && from.name != "shortVideo" && to.name == "bloggerDetails" && from.name != "fullScreen" && from.name != "original") || 
      (from.name == "shortVideo" && to.name == "vipList") ||
      (from.name == "mine" && to.name == "myLike") || 
      (from.name == "mine" && to.name == "purchasedVideo") ||
      (to.name == 'mine' && from.name == 'setUp')
    ) {
      store.dispatch('app/SET_KEEPALIVE', [])
    } else {
      store.dispatch('app/SET_KEEPALIVE', ["mainkeep", "loufengSearch", "brokerInfo", "bloggerDetails", "myLike", "moreVideo", "seArea", "purchasedVideo", "shortVideo", "videoLibrary"])
    }
    if ((to.name == 'videoInfo' && from.name == 'bloggerDetails' && to.query.fromPath != 'bloggerDetails') || (from.name == 'fullScreen' && to.name == 'introduce')) {
      let arr = store.getters.keepAliveList;
      let newArr = arr.filter(i => {
        return i != "bloggerDetails";
      })
      store.dispatch('app/SET_KEEPALIVE', newArr)
    }

    if ((to.name == 'original' && from.name == 'bloggerDetails')||to.name == 'bloggerDetails' && from.name == 'shortVideo') {
      let arr = store.getters.keepAliveList;
      let newArr = arr.filter(i => {
        return i != "bloggerDetails";
      })
      store.dispatch('app/SET_KEEPALIVE', newArr)
    }

    if (to.name == 'tag' && from.name == 'moreVideo') {
      let arr = store.getters.keepAliveList;
      let newArr = arr.filter(i => {
        return i != "moreVideo";
      })
      store.dispatch('app/SET_KEEPALIVE', newArr)
    }

    if(from.path == '/seArea' && to.path != '/videoInfo' && to.path != '/sendImg' && to.path != '/topicCircleInfo' && to.path != '/bloggerDetails'){
      let arr = store.getters.keepAliveList;
      let newArr = arr.filter(i => {
        return i != "seArea";
      })
      store.dispatch('app/SET_KEEPALIVE', newArr)
    }
    if (to.name == 'bloggerDetails' && from.name == 'bloggerShortVideo') {
      store.dispatch('app/SET_KEEPALIVE', ["bloggerDetails"]);
    }
    if (to.matched.length === 0) { //如果未能匹配到路由 则跳转到首页
        next('/')
    } else {
        next();
    }
})

const getters = {
  Uid: (state) => state.app.Uid,
  walletInfo: (state) => state.user.walletInfo,
  privilRchgInfo: (state) => state.user.privilRchgInfo,
  //配置信息
  appConfig: (state) => {
    let appConfig = state.app?.appConfig;
    if (typeof appConfig == "string") {
      appConfig = JSON.parse(state.app.appConfig);
    }
    return appConfig;
  },
  logicCfg: (state) => {
    let appConfig = JSON.parse(state.app.appConfig);
    if (appConfig) {
      return appConfig.logicCfg;
    }
  },
  waliWorldCupSwitch: (state) => {
    let appConfig = JSON.parse(state.app.appConfig);
    if (appConfig) {
      return appConfig.waliWorldCupSwitch;
    }
  },
  adsTimeLongVideo: (state) => {
    let appConfig = JSON.parse(state.app.appConfig);
    if (appConfig) {
      return appConfig.adsTimeLongVideo;
    }
  },
  aiUndressPrice: (state) => {
    let appConfig = JSON.parse(state.app.appConfig);
    if (appConfig) {
      return appConfig.aiUndressPrice;
    }
  },
  baseUrl: (state) => state.app.baseUrl,
  isLoading: (state) => state.app.isLoading,
  keepAliveList: (state) => state.app.keepAliveList,
  userInfo: (state) => {
    let userInfo = state.user.userInfo;
    if (typeof userInfo == "string") {
      userInfo = JSON.parse(state.user.userInfo);
    }
    return userInfo;
  },
  //是否vip
  isVip: (state) => {
    let userInfo = state.user?.userInfo;
    if (typeof userInfo == "string") {
      userInfo = JSON.parse(state.user.userInfo);
    }
    return Number(userInfo.vipExpire * 1000) > Number(new Date().getTime());
  },
  //热门视频
  videoObj: (state) => {
    return state.video.videoObj;
  },
  //关注视频
  videoObjCar: (state) => {
    return state.video.videoObjCar;
  },
  //社区视频
  communityObj: (state) => {
    return state.video.communityObj;
  },
  // 选择的线路
  videoRoadLine: (state) => {
    let videoRoadLine = state.video?.videoRoadLine;
    if (typeof videoRoadLine == "string") {
        videoRoadLine = JSON.parse(state.video.videoRoadLine);
    }
    return videoRoadLine;
  },
};
export default getters;

import {
    Api
} from "@/utils/index.js";
import {
    queryWallet,
    getUserInfo
} from "@/api/user.js";
const state = {
    // 钱包信息
    walletInfo: {},
    userInfo: sessionStorage.getItem("userInfo"),
    privilRchgInfo: {
        status: false,
        payModes: [], // 充值方式
        dcInfo: {}, // 代充信息
        dcChannel: '', // 代充域名
        payAmount: '', // 支付金额
        useId: '',
    },
}

const mutations = {
    // 钱包信息
    SET_WALLET: (state, walletInfo) => {
        state.walletInfo = walletInfo;
    },
    // 用户信息
    SET_USERINFO: (state, info) => {
        state.userInfo = info;
    },
    // 特权卡充值信息
    SET_PRIVILRCHGINFO: (state, info) => {
        state.privilRchgInfo = info;
    }
}

const actions = {
    async setWallet({
        commit
    }) {
        let ret = await Api(queryWallet, undefined);
        if (ret && ret.code === 200) {
            commit("SET_WALLET", ret.data.wInfo);
            return ret.data.wInfo;
        }
    },
    // 获取用户信息
    async getUserInfo({
        commit
    }) {
        let ret = await Api(getUserInfo)
        if (ret.code == 200) {
            sessionStorage.setItem("userInfo", JSON.stringify(ret.data.info))
            commit("SET_USERINFO", ret.data.info);
        }
    },
    setPrivilRchgInfo({
        commit
    }, info) {
        commit("SET_PRIVILRCHGINFO", info);
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
<template>
  <div class="splash">
    <div class="countdown" @click="goApp" v-if="isStateTime">
      <span v-if="countdown != 0">
        {{ countdown }}
      </span>
      <svg-icon iconClass="close" class="close" v-else></svg-icon>
    </div>
    <transition name="shareShow">
      <ImgDecypt
        :src="ATP_LaunchList[0].pictureUrl"
        :isLoading="false"
        class="LaunchImg"
        v-if="ATP_LaunchList.length"
        @click.native="jump"
      />
    </transition>
    <EnterGroupPopup v-if="isCheckError" />
    <div class="selectLineBox" v-if="loading">
      <van-loading color="#1989fa" size="30" />
      <div class="selectLine">选线中...</div>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
<script>
import { loginGuest } from "@/api/user.js";
import { getConfig } from "@/api/app.js";
import { Dialog } from "vant";
import { getQueryString } from "@/utils";
import { getAdItem, AdType } from "@/utils/getAdv.js";
// import ping from "@/utils/ping.js";
import { jumpAdv } from "@/utils/getAdv.js";
import EnterGroupPopup from "@/components/EnterGroupPopup/index.vue";
import axios from "axios";
import store from "@/store/index.js";
export default {
  components: {
    EnterGroupPopup,
  },
  data() {
    return {
      timer: null,
      countdown: 5, //广告倒计时
      retryNum: 3, //获取token失败重试次数
      ATP_LaunchList: [], //启动也广告
      isStateTime: false, //是否开始显示倒计时
      isCheckError: false, //是否选线失败
      checkLineErrorNum: 0, //选线失败次数
      isResLock: false, // 请求结果锁
      resResult: null, // 响应结果
      pingCheckCount: 0, // 请求的ping失败次数
      loading: true, // 选线loading
    };
  },
  async created() {
    this.init();
  },

  methods: {
    // 跳转广告
    jump() {
      jumpAdv(this.ATP_LaunchList[0]);
    },
    //开机广告
    adControl() {
      this.ATP_LaunchList = getAdItem(AdType.ATP_Launch);
      this.countdownStrat();
      this.isStateTime = true;
    },
    //启动倒计时
    countdownStrat() {
      this.loading = false;
      this.timer = setInterval(() => {
        if (this.countdown <= 0) {
          clearInterval(this.timer);
        } else {
          this.countdown--;
        }
      }, 1000);
    },
    //是否有渠道信息
    getChannelInfo() {
      if (
        getQueryString("dc") ||
        getQueryString("ch") ||
        getQueryString("pc")
      ) {
        return true;
      }
      return false;
    },
    //设置token
    async setToken() {
      let Uid = localStorage.getItem("Uid");
      let token = localStorage.getItem("Authorization");
      if (!Uid) {
        this.$store.dispatch("app/GET_UID");
      }
      if (token) return;
      let req = {
        devType: 2,
        devID: this.$store.getters.Uid,
      };
      if (this.getChannelInfo()) {
        req.affCode = JSON.stringify(this.$route.query);
      }
      try {
        let ret = await this.$Api(loginGuest, req);
        if (ret && ret.code == 200) {
          localStorage.setItem("Authorization", ret.data.token);
          return;
        }
        //如果不等于 200 进行重试
        this.retryNum--;
        if (!token && this.retryNum >= 0) {
          await this.setToken();
        }
      } catch (error) {
        this.retryNum--;
        if (!token && this.retryNum >= 0) {
          await this.setToken();
        }
      }
    },
    //获取全局配置
    async getConfigData() {
      let req = { sysCfgState: -1 };
      let ret = await this.$Api(getConfig, req);
      if (ret && ret.code == 200) {
        this.$store.dispatch("app/GET_APPCONFIG", JSON.stringify(ret.data));

        let imgDomainList = ret.data?.videoCfg?.newCdns;
        console.log(imgDomainList);
        if (imgDomainList && imgDomainList.length > 0) {
          this.$store.commit("video/SET_VIDEOROADLINE", imgDomainList[0]);
        }
        sessionStorage.setItem(
          "logUrl",
          JSON.stringify(ret.data.dataBuriedPoint)
        );
      }
    },
    //初始化数据
    async init() {
      const baseUrls = process.env.VUE_APP_BASE_HOSTS || "[]";
      const hosts = JSON.parse(baseUrls);
      this.resResult = new Array(hosts.length);
      hosts.forEach((url, index) => {
        axios({
          method: "POST",
          url: url + process.env.VUE_APP_BASE_PATH + "ping",
          timeout: 1000 * 10, // 10秒超时
        })
          .then(async () => {
            if (!this.isResLock) {
              this.isResLock = true;
              this.resResult[index] = true;
              // let baseUrl = line + process.env.VUE_APP_BASE_PATH
              sessionStorage.setItem(
                "baseUrl",
                url + process.env.VUE_APP_BASE_PATH
              );
              store.dispatch(
                "app/GET_REQUESTURL",
                url + process.env.VUE_APP_BASE_PATH
              );
              await this.setToken();
              await this.getConfigData();
              this.$store.dispatch("user/getUserInfo"); //获取用户信息
              this.adControl();
            }
          })
          .catch(() => {
            // 只有最后一条域名出错时，判断响应结果不存在true的时候，说明全部不通，即弹出选线失败
            this.pingCheckCount++;
            if (this.pingCheckCount == hosts.length) {
              this.pingCheckCount = 0;
              let index = this.resResult.indexOf(true);
              if (index == -1) {
                if (this.checkLineErrorNum == 1) {
                  this.isCheckError = true;
                  return;
                }
                Dialog.alert({
                  title: "提示",
                  message: "选线失败",
                  confirmButtonText: "重试",
                  theme: "round-button",
                }).then(() => {
                  this.checkLineErrorNum++;
                  this.init();
                });
              }
            }
          });
      });
      // console.log(123213);
      // let isOk = await ping();
      // if (!isOk) {
      //   if (this.checkLineErrorNum == 1) {
      //     this.isCheckError = true;
      //     return;
      //   }
      // Dialog.alert({
      //   title: "提示",
      //   message: "选线失败",
      //   confirmButtonText: "重试",
      //   theme: "round-button",
      // }).then(() => {
      //   this.checkLineErrorNum++;
      //   this.init();
      // });
      //   return;
      // }
      // await this.setToken();
      // await this.getConfigData();
      // this.$store.dispatch("user/getUserInfo"); //获取用户信息
      // this.adControl();h("user/getUserInfo"); //获取用户信息
      this.adControl();
    },
    goApp() {
      if (this.countdown != 0) return;
      this.$router.replace({ path: "/home/tag" });
    },
  },

  destroying() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.splash {
  width: 375px;
  height: 100%;
  background: url("../../assets/png/splash.jpg") center no-repeat;
  background-size: 100% 100%;
  position: relative;
  .LaunchImg {
    width: 100%;
    height: 100%;
    /deep/ img {
      object-fit: cover !important;
    }
  }
  .countdown {
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 999;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px;
  }
}
.close {
  font-size: 30px;
}
/* 淡隐淡现 */
.shareShow-enter-active,
.shareShow-leave-active {
  transition: all 0.3s linear;
}

.shareShow-enter-to,
.shareShow-leave {
  opacity: 1;
}

.shareShow-leave-to,
.shareShow-enter {
  opacity: 0;
}
.selectLineBox {
  height: 120px;
  width: 150px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .selectLine {
    margin-top: 20px;
    font-size: 16px;
  }
}
</style>

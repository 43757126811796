import axios from "@/utils/request";

//检查视频是否可以播放
export function pingVideo(url) {
  return axios.get(url);
}

//获取视频详情
export function verifyPlayVideo(data) {
  return axios.post(`/media/verifyPlay`, data);
}
//搜索视频
export function sendSearchMedia(data) {
  return axios.post(`/search/medias`, data);
}
//转发视频
export function publishSeBaVideo(data) {
  return axios.post(`/media/sexArea/pub`, data);
}
//购买金币视频
export function sendMediaBuy(data) {
  return axios.post(`/media/buy`, data);
}

//广告点击
export function adClickReport(data) {
  return axios.post(`/ad/click`, data);
}
// 获取在线客服
export function getCustom() {
  return axios.get(`/custom/h5`);
}
// 行为点击量
export function buried(data) {
  return axios.post(`/buried`, data);
}
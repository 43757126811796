export var videotime = (value) => { //视频时长过滤器
  if (!value) return 0;
  if (value >= 3600) { //如果大于一个小时
    return (Math.floor(value / 3600) < 10 ? '0' + Math.floor(value / 3600) : Math.floor(value / 3600)) + ':' + (Math.floor(value % 3600 / 60) < 10 ? '0' + Math.floor(value % 3600 / 60) : Math.floor(value % 3600 / 60)) + ':' + ((value % 3600 % 60) < 10 ? '0' + Math.floor(value % 3600 / 60) : Math.floor(value % 3600 / 60));
  } else if (value >= 60) { //如果大于一分钟
    return (Math.floor(value / 60) < 10 ? '0' + Math.floor(value / 60) : Math.floor(value / 60)) + ':' + (value % 60 < 10 ? '0' + value % 60 : value % 60);
  } else {
    return '00:' + (value < 10 ? '0' + value : value);
  }
}

export var watchCount = (value) => { //点赞 观看数量过滤器 例如超过一万就显示1.几万
  // if(!value) return 0;
  // if(value >= 10000){
  //     return (Math.round(value / 1000) / 10).toFixed(1) + 'w';
  // }else{
  //     return value;
  // }
  let str = value || 0;
  if (value >= 10000) {
    let totleNum = (value / 10000).toFixed(1);
    str = `${parseFloat(totleNum)}w`;
  }
  return str;
}

export var time = (date) => { // 时间过滤器返回2019-04-19 10:52:21
  if (!date) return "0000-00-00 00:00:00";
  let time = new Date(date);
  return time.getFullYear() + '-' +
    ((time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : (time.getMonth() + 1)) + '-' +
    (time.getDate() < 10 ? '0' + time.getDate() : time.getDate()) + ' ' +
    (time.getHours() < 10 ? '0' + time.getHours() : time.getHours()) + ':' +
    (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()) + ':' +
    (time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds())
}

export var date = (date) => { // 时间过滤器返回2019-04-19
  if (!date) return "0000-00-00 00:00:00";
  let time = new Date(date);
  return time.getFullYear() + '-' +
    ((time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : (time.getMonth() + 1)) + '-' +
    (time.getDate() < 10 ? '0' + time.getDate() : time.getDate())
}

export var dateago = (date) => { //评论列表里面的时间过滤器 超过一天就显示某年某月某日 没超过一天就显示几小时 几分钟前
  if (!date) return 0;
  date *= 1000;
  var dateago = new Date(date);
  var dateNum = dateago.getTime();
  var separate = new Date().getTime() - dateNum; //与现在相差的时间戳
  if (separate > 86400000) { //如果时间大于一天
    return dateago.getFullYear() + '年' + (dateago.getMonth() + 1) + '月' + dateago.getDate() + '日';
  } else if (separate > 3600000) { //如果大于一个小时
    return Math.floor(separate / 3600000) + '小时前';
  } else if (separate > 60000) { //如果大于一分钟
    return Math.floor(separate / 60000) + '分钟前';
  } else {
    return '刚刚';
  }
}
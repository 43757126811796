<template>
  <div class="img_Decypt">
    <lazy-component class="laz_com">
      <LazyImg
        :src="src"
        :isDecypt="isDecypt"
        :isLoading="isLoading"
        :round="round"
      />
      <slot />
    </lazy-component>
  </div>
</template>
<script>
import LazyImg from "@/components/LazyImg/index";

export default {
  props: ["src", "isDecypt", "isLoading", "round"],
  components: {
    LazyImg,
  },
};
</script>
<style scoped lang="scss">
.img_Decypt {
  height: 100%;

  .laz_com {
    height: 100%;
  }
}
</style>

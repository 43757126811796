<template>
  <van-popup v-model="show" round :close-on-click-overlay="false">
    <div class="warp">
      <h1>系统提示</h1>
      <div class="content">
        <h2>APP线路检测失败</h2>
        <h3>解决方案</h3>
        <p>1.请检测您的网络是否正常，手机是否开了VPN或10分钟后再尝试访问。</p>
        <p>2.前往官网地址 sis58.com 下载最新版。</p>
        <p>3.前往Telegram色中色官方交流群</p>
        <a href="https://t.me/sisflq" target="_blank">前往群聊</a>
      </div>
    </div>
  </van-popup>
</template>
<script>
export default {
  data() {
    return {
      show: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.warp {
  width: 318px;
  background-color: #ffffff;
  h1 {
    font-size: 22px;
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
  }
  .content {
    padding: 14px 31px;
    color: #333333;
    text-align: center;
    h2 {
      font-size: 14px;
      color: #ea0011;
      font-weight: 500;
      padding-bottom: 8px;
      text-align: left;
    }
    h3 {
      font-size: 14px;
      color: #333333;
      padding-bottom: 6px;
      font-weight: bold;
      text-align: left;
    }
    p {
      line-height: 20px;
      text-align: left;
    }
    a {
      margin: 15px auto 6px;
      display: inline-block;
      width: 216px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background-color: #f21b2b;
      color: #ffffff;
      border-radius: 30px;
      font-size: 14px;
    }
  }
}
</style>

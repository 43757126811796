import Vue from "vue";
import VueRouter from "vue-router";
import Splash from "../views/widget/Splash.vue";
import Main from "../views/Main.vue";

Vue.use(VueRouter);

const Home = () => import("@/views/home"); //首页
const search = () => import("@/views/home/search"); //搜索页
const searchResult = () => import("@/views/home/searchResult"); //热搜结果页
const searchTagResult = () => import("@/views/home/searchTagResult"); //热搜标签结果页
const goodVideo = () => import("@/views/home/goodVideo"); //专区好片大赏
const moreVideo = () => import("@/views/home/moreVideo"); //更多视频页
const rankMoreVideo = () => import("@/views/home/rankMoreVideo"); //首页排行的更多视频页 
const userMoreVideo = () => import("@/views/home/userMoreVideo"); //首页排行博主的更多视频页
const pastTag = () => import("@/views/home/pastTag"); //专题下面的往期主页
const seArea = () => import("@/views/home/seArea"); //专题下面的色区
const topicCircle = () => import("@/views/home/topicCircle"); //专题下面的色区的话题圈
const topicCircleInfo = () => import("@/views/home/topicCircleInfo"); //专题下面的色区的话题圈详情页面
const sendImg = () => import("@/views/home/sendImg"); //专题下面的色区里的发布图文
const rules = () => import("@/views/home/rules"); //专题下面的色区里的发布图文的规则说明
const introduce = () => import('@/views/home/introduce'); //首页推荐
const tag = () => import('@/views/home/tag'); //首页专题
const rank = () => import('@/views/home/rank'); //首页排行
const fullScreen = () => import('@/views/home/fullScreen'); //全屏视频播放页面
const tagResult = () => import('@/views/home/tagResult'); //全屏视频播放的标签搜索结果页
const picturePreview = () => import('@/views/home/picturePreview'); //图片预览

const OnDemand = () => import("@/views/on_demand"); //点播
const Recommend = () => import("@/views/on_demand/recommend"); //点播推荐
const Exclusive = () => import("@/views/on_demand/exclusive"); //点播独家
const Original = () => import("@/views/on_demand/original"); //点播原创
const bloggerMore = () => import("@/views/on_demand/original/bloggerMore"); //博主更多
const BloggerDetails = () => import("@/components/BloggerDetails"); //博主详情
const UserDetails = () => import("@/components/UserDetails"); //用户详情
const AvDetails = () => import("@/views/on_demand/exclusive/AvCommentary/av_details"); //AV详情

const ShortVideo = () => import("@/views/short_video"); // 短视频
const BloggerShortVideo = () => import("@/views/blogger_short_video"); // 博主短视频

const videoInfo = () => import("@/views/videoInfo/index.vue"); //视频详情

const Game = () => import("@/views/game"); //游戏
const myBill = () => import("@/views/game/myBill"); //游戏账单
const gameWallet = () => import("@/views/game/gameWallet"); //游戏钱包
const problem = () => import("@/views/game/problem"); //游戏常见问题
const withdraw = () => import("@/views/game/withdraw"); //游戏提现
const gamePlay = () => import("@/views/game/gameplay"); //玩游戏页面

const loufeng = () => import("@/views/loufeng"); //楼风
const loufengDetail = () => import("@/views/loufeng/loufengDetail"); //楼凤详情
const nudeChatDetails = () => import("@/views/loufeng/nudeChat/nudeChatDetails"); //裸聊详情
const nudeChatReport = () => import("@/views/loufeng/nudeChat/nudeChatReport"); //裸聊详情
const guide = () => import("@/views/loufeng/guide"); //防骗指南
const loufengBigPicture = () => import("@/views/loufeng/loufengBigPicture"); //楼凤放大图
const loufengExperReort = () => import("@/views/loufeng/loufengExperReort"); //楼凤体验报告
const loufengReport = () => import("@/views/loufeng/loufengReport"); //楼凤举报
const loufengSearch = () => import("@/views/loufeng/loufengSearch"); //楼凤搜索
const loufengLocation = () => import("@/views/loufeng/loufengLocation"); //楼凤位置
const brokerInfo = () => import("@/views/loufeng/brokerInfo"); //经纪人

const videoLibrary = () => import("@/views/videoLibrary"); //片库

const Mine = () => import("@/views/mine"); //我的
const coupon = () => import("@/views/mine/coupon"); //优惠券
const focusOn = () => import("@/views/mine/focusOn"); //关注
const promote = () => import("@/views/mine/promote"); //推广
const incomeBreakdown = () => import("@/views/mine/promote/incomeBreakdown"); //收益明细
const promotionRecord = () => import("@/views/mine/promote/promotionRecord"); //推广记录
const promotionCode = () => import("@/views/mine/promote/promotionCode"); //推广赚钱
const fanClub = () => import("@/views/mine/fanClub"); //我的粉丝团
const myCommunity = () => import("@/views/mine/myCommunity"); //我的色区
const myLike = () => import("@/views/mine/myLike"); //我喜欢的
const purchasedVideo = () => import("@/views/mine/purchasedVideo"); //已购视频
const appoinService = () => import("@/views/mine/appoinService"); //预约服务
const myNakedChat = () => import("@/views/mine/myNakedChat"); //我的裸聊
const setUp = () => import("@/views/mine/setUp"); //设置
const basicInfo = () => import("@/views/mine/setUp/basicInfo"); //基本信息
const fans = () => import("@/views/mine/fans"); //粉丝
const watchHistory = () => import("@/views/mine/watchHistory"); //观看记录
const funds = () => import("@/views/mine/funds"); //会员、钱包
const vipList = () => import("@/views/mine/funds/vip"); //会员
const wallet = () => import("@/views/mine/funds/wallet"); //钱包
const goldWithdraw = () => import("@/views/mine/funds/wallet/goldWithdraw"); //钱包
const bindBankCard = () => import("@/views/mine/bindBankCard"); //绑定银行卡
const bankCard = () => import("@/views/mine/bankCard"); //银行卡
const rightsCompared = () => import("@/views/mine/funds/vip/morePrivilege"); //权益对比
const transaDetails = () => import("@/views/mine/funds/transaDetails"); //交易明细
const login = () => import("@/views/login"); //登录
const newLogin = () => import("@/views/newLogin"); //验证码登录
const register = () => import("@/views/register"); //注册
const sendCode = () => import("@/views/register/sendCode"); //发送验证码
const forgetPwd = () => import("@/views/forgetPwd"); //忘记密码
const resetPwd = () => import("@/views/forgetPwd/resetPwd"); //重置密码
const scan = () => import("@/views/mine/setUp/scan"); //扫码登陆
const certificate = () => import("@/views/mine/setUp/scan/certificate"); //账号凭证
const bindIphone = () => import("@/views/mine/bindIphone"); //绑定手机号
const aiUndress = () => import("@/views/mine/aiUndress"); //AI脱衣
const aiRecord = () => import("@/views/mine/aiRecord"); //AI脱衣记录


const routes = [{
    path: "/",
    name: "splash",
    component: Splash,
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      index: 3,
      noToken: true,
    },
  },
  {
    path: "/newLogin",
    name: "newLogin",
    component: newLogin,
    meta: {
      index: 4,
      noToken: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: register,
    meta: {
      index: 4,
      noToken: true,
    },
  },
  {
    path: "/forgetPwd",
    name: "forgetPwd",
    component: forgetPwd,
    meta: {
      index: 4,
      noToken: true,
    },
  },
  {
    path: "/sendCode",
    name: "sendCode",
    component: sendCode,
    meta: {
      index: 5,
      noToken: true,
    },
  },
  {
    path: "/resetPwd",
    name: "resetPwd",
    component: resetPwd,
    meta: {
      index: 5,
      noToken: true,
    },
  },
  {
    path: "/coupon",
    name: "coupon",
    component: coupon,
    meta: {
      index: 2,
      loginRequest: true,
    },
  },
  {
    path: "/tagResult",
    name: "tagResult",
    component: tagResult,
    meta: {
      index: 6,
    },
  },
  {
    path: "/picturePreview",
    name: "picturePreview",
    component: picturePreview,
    meta: {
      index: 3,
    },
  },
  {
    path: "/focusOn",
    name: "focusOn",
    component: focusOn,
    meta: {
      index: 2,
      loginRequest: true,
    },
  },
  {
    path: "/search",
    name: "search",
    component: search,
    meta: {
      index: 4,
    },
  },
  {
    path: "/promote",
    name: "promote",
    component: promote,
    meta: {
      index: 2,
      loginRequest: true,
    }
  },
  {
    path: "/fullScreen",
    name: "fullScreen",
    component: fullScreen,
    meta: {
      index: 5,
    }
  },
  {
    path: "/fanClub",
    name: "fanClub",
    component: fanClub,
    meta: {
      index: 2,
      loginRequest: true,
    }
  },
  {
    path: "/myCommunity",
    name: "myCommunity",
    component: myCommunity,
    meta: {
      index: 2,
      loginRequest: true,
    }
  },
  {
    path: "/myLike",
    name: "myLike",
    component: myLike,
    meta: {
      index: 2,
      loginRequest: true,
    }
  },
  {
    path: "/purchasedVideo",
    name: "purchasedVideo",
    component: purchasedVideo,
    meta: {
      index: 2,
      loginRequest: true,
    }
  },
  {
    path: "/appoinService",
    name: "appoinService",
    component: appoinService,
    meta: {
      index: 2,
      loginRequest: true,
    }
  },
  {
    path: "/myNakedChat",
    name: "myNakedChat",
    component: myNakedChat,
    meta: {
      index: 2,
      loginRequest: true,
    }
  },
  {
    path: "/watchHistory",
    name: "watchHistory",
    component: watchHistory,
    meta: {
      index: 2,
      loginRequest: true,
    }
  },
  {
    path: "/bindBankCard",
    name: "bindBankCard",
    component: bindBankCard,
    meta: {
      index: 5,
      loginRequest: true,
    }
  },
  {
    path: "/bankCard",
    name: "bankCard",
    component: bankCard,
    meta: {
      index: 4,
      loginRequest: true,
    }
  },
  {
    path: "/goldWithdraw",
    name: "goldWithdraw",
    component: goldWithdraw,
    meta: {
      index: 3,
      loginRequest: true,
    }
  },
  {
    path: "/rightsCompared",
    name: "rightsCompared",
    component: rightsCompared,
    meta: {
      index: 4,
      loginRequest: true,
    }
  },
  {
    path: "/transaDetails",
    name: "transaDetails",
    component: transaDetails,
    meta: {
      index: 4,
      loginRequest: true,
    }
  },
  {
    path: "/funds",
    name: "funds",
    component: funds,
    meta: {
      index: 2,
      loginRequest: true,
    },
    children: [{
        path: "/vipList",
        name: "vipList",
        component: vipList,
        meta: {
          index: 3,
          loginRequest: true,
        },
      },
      {
        path: "/wallet",
        name: "wallet",
        component: wallet,
        meta: {
          index: 3,
          loginRequest: true,
        },
      },
    ]
  },
  {
    path: "/fans",
    name: "fans",
    component: fans,
    meta: {
      index: 2,
      loginRequest: true,
    }
  },
  {
    path: "/setUp",
    name: "setUp",
    component: setUp,
    meta: {
      index: 2,
      loginRequest: true,
    }
  },
  {
    path: "/basicInfo",
    name: "basicInfo",
    component: basicInfo,
    meta: {
      index: 3,
    }
  },
  {
    path: "/incomeBreakdown",
    name: "incomeBreakdown",
    component: incomeBreakdown,
    meta: {
      index: 3,
      loginRequest: true,
    }
  },
  {
    path: "/promotionRecord",
    name: "promotionRecord",
    component: promotionRecord,
    meta: {
      index: 3,
      loginRequest: true,
    }
  },
  {
    path: "/promotionCode",
    name: "promotionCode",
    component: promotionCode,
    meta: {
      index: 3,
      loginRequest: true,
    }
  },
  {
    path: "/searchTagResult",
    name: "searchTagResult",
    component: searchTagResult,
    meta: {
      index: 5,
    },
  },
  {
    path: "/moreVideo",
    name: "moreVideo",
    component: moreVideo,
    meta: {
      index: 3,
    },
  },
  {
    path: "/rankMoreVideo",
    name: "rankMoreVideo",
    component: rankMoreVideo,
    meta: {
      index: 3,
    },
  },
  {
    path: "/userMoreVideo",
    name: "userMoreVideo",
    component: userMoreVideo,
    meta: {
      index: 3,
    },
  },
  {
    path: "/goodVideo",
    name: "goodVideo",
    component: goodVideo,
    meta: {
      index: 3,
    },
  },
  {
    path: "/pastTag",
    name: "pastTag",
    component: pastTag,
    meta: {
      index: 4,
    },
  },
  {
    path: "/seArea",
    name: "seArea",
    component: seArea,
    meta: {
      index: 3,
    },
  },
  {
    path: "/sendImg",
    name: "sendImg",
    component: sendImg,
    meta: {
      index: 4,
    },
  },
  {
    path: "/rules",
    name: "rules",
    component: rules,
    meta: {
      index: 5,
    },
  },
  {
    path: "/topicCircle",
    name: "topicCircle",
    component: topicCircle,
    meta: {
      index: 4,
    },
  },
  {
    path: "/topicCircleInfo",
    name: "topicCircleInfo",
    component: topicCircleInfo,
    meta: {
      index: 5,
    },
  },
  {
    path: "/searchResult",
    name: "searchResult",
    component: searchResult,
    meta: {
      index: 5,
    },
  },
  {
    path: "/myBill",
    name: "myBill",
    component: myBill,
    meta: {
      index: 2,
      loginRequest: true,
    },
  },
  {
    path: "/gameWallet",
    name: "gameWallet",
    component: gameWallet,
    meta: {
      index: 1,
      loginRequest: true,
    },
  },
  {
    path: "/problem",
    name: "problem",
    component: problem,
    meta: {
      index: 2,
    },
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: withdraw,
    meta: {
      index: 1,
      loginRequest: true,
    },
  },
  {
    path: "/gamePlay",
    name: "gamePlay",
    component: gamePlay,
    meta: {
      index: 1,
    },
  },
  {
    path: "/bloggerMore",
    name: "bloggerMore",
    component: bloggerMore,
    meta: {
      index: 4,
    },
  },
  {
    path: "/bloggerDetails",
    name: "bloggerDetails",
    component: BloggerDetails,
    meta: {
      index: 6,
    },
  },
  {
    path: "/userDetails",
    name: "userDetails",
    component: UserDetails,
    meta: {
      index: 3,
    },
  },
  {
    path: "/avDetails",
    name: "avDetails",
    component: AvDetails,
    meta: {
      index: 3,
    },
  },
  {
    path: "/videoInfo",
    name: "videoInfo",
    component: videoInfo,
    meta: {
      index: 5,
    },
  },
  {
    path: "",
    name: "mainkeep",
    component: Main,
    children: [{
        path: "/home",
        name: "home",
        component: Home,
        meta: {
          index: 0,
        },
        children: [{
            path: '/home/introduce',
            name: 'introduce',
            component: introduce,
            meta: {
              index: 0,
            },
          },
          {
            path: '/home/tag',
            name: 'tag',
            component: tag,
            meta: {
              index: 1,
            },
          },
          {
            path: '/home/rank',
            name: '/rank',
            component: rank,
            meta: {
              index: 2,
            },
          }
        ]
      },
      {
        path: "/onDemand",
        name: "onDemand",
        component: OnDemand,
        meta: {
          index: 0,
        },
        children: [{
            path: '/onDemand/recommend',
            name: 'recommend',
            component: Recommend,
            meta: {
              index: 1,
            },
          },
          {
            path: '/onDemand/exclusive',
            name: 'exclusive',
            component: Exclusive,
            meta: {
              index: 2,
            },
          },
          {
            path: '/onDemand/original',
            name: 'original',
            component: Original,
            meta: {
              index: 3,
            },
          }
        ]
      },
      {
        path: '/shortVideo',
        name: 'shortVideo',
        component: ShortVideo,
        meta: {
          index: 0
        }
      },
      {
        path: "/game",
        name: "game",
        component: Game,
        meta: {
          index: 0,
        },
      },
      {
        path: "/loufeng",
        name: "loufeng",
        component: loufeng,
        meta: {
          index: 0,
        }
      },
      {
        path: "/videoLibrary",
        name: "videoLibrary",
        component: videoLibrary,
        meta: {
          index: 0,
        },
      },
      {
        path: "/mine",
        name: "mine",
        component: Mine,
        meta: {
          index: 0,
          // loginRequest: true,
        }
      }
    ],
  },
  {
    path: '/bloggerShortVideo',
    name: 'bloggerShortVideo',
    component: BloggerShortVideo,
    meta: {
      index: 1
    }
  },
  {
    path: '/guide',
    name: 'guide',
    component: guide
  },
  {
    path: '/loufengDetail',
    name: 'loufengDetail',
    component: loufengDetail,
    meta: {
      index: 3,
      loginRequest: true,
    }
  },
  {
    path: '/nudeChatDetails',
    name: 'nudeChatDetails',
    component: nudeChatDetails,
    meta: {
      index: 3,
      loginRequest: true,
    }
  },
  {
    path: '/nudeChatReport',
    name: 'nudeChatReport',
    component: nudeChatReport,
    meta: {
      index: 4,
      loginRequest: true,
    }
  },
  {
    path: '/loufengBigPicture',
    name: 'loufengBigPicture',
    component: loufengBigPicture
  },
  {
    path: '/loufengExperReort',
    name: 'loufengExperReort',
    component: loufengExperReort
  },
  {
    path: '/loufengReport',
    name: 'loufengReport',
    component: loufengReport
  },
  {
    path: '/loufengSearch',
    name: 'loufengSearch',
    component: loufengSearch,
  },
  {
    path: '/loufengLocation',
    name: 'loufengLocation',
    component: loufengLocation
  },
  {
    path: '/brokerInfo',
    name: 'brokerInfo',
    component: brokerInfo
  },
  {
    path: '/scan',
    name: 'scan',
    component: scan,
    meta: {
      index: 2,
    }
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: certificate,
    meta: {
      index: 3,
    }
  },
  {
    path: '/bindIphone',
    name: 'bindIphone',
    component: bindIphone,
    meta: {
      index: 3,
    }
  },
  {
    path: "/aiRecord",
    name: "aiRecord",
    component: aiRecord,
    meta: {
      index: 3,
    },
  },
  {
    path: "/aiUndress",
    name: "aiUndress",
    component: aiUndress,
    meta: {
      index: 3,
    },
  },
];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router;
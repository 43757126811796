import route from "@/router/index.js"
import {
  openDB,
  addData,
  cursorGetDataByIndexAndPage,
  closeDB,
  deleteDB,
  getDataByKey,
  getDataCount,
  // cursorGetData,
  UpdateData
} from "./indexedDB"
/**
 * 不经过action的异步
 * @param {Function} fn 异步函数
 * @param {} payload 参数
 * @param {boolean} asyncFlag 请求是否等待上一个请求结束
 */
let isPass = true;
let holdRequest = [];
export async function Api(fn, payload, asyncFlag = false) {
  if (asyncFlag) {
    let ret;
    if (fn) {
      holdRequest.push(fn);
    }
    if (!isPass) return;
    isPass = false;
    if (holdRequest.length > 0) {
      let popRequest = holdRequest.pop();
      try {
        let {
          data
        } = await popRequest(payload);
        ret = data;
      } catch (err) {
        ret = Promise.resolve(err);
      } finally {
        isPass = true;
        Api(undefined, undefined, true)
      }
      return ret;
    }

  } else {
    let {
      data
    } = await fn(payload)
    return data
  }
}

/**
 *  截取url参数
 * @param {*} name 截取url参数名称
 * @returns 
 */
export function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}

// 获取设备类型
export function getDevType() {
  let type = '';
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
    type = 'ios';
  } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
    type = 'android';
  } else {
    type = 'pc';
  }
  return type;
}



/**
 * 跳转视频到详情页
 * @param {*} mediaInfo  视频信息
 */
export function jumpVideo(mediaInfo, fromPath) {
  mediaInfo.saveTime = Date.now();
  addVideoHistory(mediaInfo);
  if (mediaInfo.height > mediaInfo.width) { //如果长大于宽
    let videoList = [];
    videoList.push(mediaInfo);
    let obj = {
      index: 0,
      list: videoList
    }
    localStorage.setItem('oneVideoList', JSON.stringify(obj));
    route.push('/fullScreen');
  } else {
    let path = route.history.current.path;
    if (path == '/videoInfo') {
      route.replace({
        path: "/videoInfo",
        query: {
          id: mediaInfo.id,
          fromPath: fromPath,
        }
      });
      return;
    }
    route.push({
      path: "/videoInfo",
      query: {
        id: mediaInfo.id,
        fromPath: fromPath,
      }
    });
  }
}

// 保存视频历史记录
export async function addVideoHistory(data) {
  let db = await openDB('sis_db', 1);
  try {
    let queryVideo = await getDataByKey(db, 'mediaInfos', data.id)
    if (queryVideo) {
      deleteDB(db, 'mediaInfos', data.id)
    }
    let count = await getDataCount(db, 'mediaInfos')
    if (count >= 100) {
      let list = await cursorGetDataByIndexAndPage(db, 'mediaInfos', 1, 1, 'next', 'saveTime');
      let id = list[0].id;
      deleteDB(db, 'mediaInfos', id)
    }
    addData(db, 'mediaInfos', data);
    closeDB(db);
  } catch (error) {
    closeDB(db);
  }
}

//查询视频历史记录
export async function queryHistoryVideo(page, size) {
  let db = await openDB('sis_db');
  let list = await cursorGetDataByIndexAndPage(db, 'mediaInfos', page, size, 'prev', 'saveTime')
  return list || []
}

// 保存滚动条
export async function setScrollTop(className, path, sonPath, scrollTop) {
  let db = await openDB('sis_db')
  let data = {
    className: className,
    path: path,
    scrollTop: scrollTop
  }
  if (sonPath) {
    data.sonPath = sonPath;
  }
  UpdateData(db, 'scrollBar', data);
  // }
}

<template>
  <div class="img-decypt">
    <van-image :src="decyImg" class="vanImage" :round="round" fit="cover">
      <template v-slot:loading>
        <svg-icon
          iconClass="place"
          class="imgLoading"
          v-if="isLoading"
        ></svg-icon>
        <!-- 兼容启动页 -->
        <div v-if="!isLoading" class="splash-loading-img"></div>
      </template>
      <template v-slot:error>
        <svg-icon
          iconClass="place"
          class="imgLoading"
          v-if="isLoading"
        ></svg-icon>
        <!-- 兼容启动页 -->
        <div v-if="!isLoading" class="splash-error-img"></div>
      </template>
      <slot></slot>
    </van-image>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { imgDecypt } from "@/api/app.js";
export default {
  props: {
    src: {
      type: String,
      required: true,
      default() {
        return "";
      },
    },
    isDecypt: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    round: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    isLoading: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    fill: {
      type: String,
      required: false,
      default() {
        return "fill";
      },
    },
  },
  data() {
    return {
      decyImg: "",
      loadingImg: require("@/assets/png/splash.jpg"),
    };
  },
  computed: {
    ...mapGetters({
      logicCfg: "logicCfg",
    }),
  },
  async created() {
    if (!this.isDecypt) {
      this.decyImg = this.src;
    } else {
      try {
        let imgDomain = this.logicCfg.imgDomain;
        if (imgDomain.slice(-1) != "/") {
          imgDomain = imgDomain + "/";
        }
        if (!imgDomain || !this.src) return;
        imgDecypt(imgDomain + this.src).then((url) => {
          this.decyImg = url;
        });
      } catch (error) {
        console.log(error);
      }
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.splash-loading-img,
.splash-error-img {
  width: 375px;
  height: 100%;
  background: url("../../assets/png/splash.jpg") center no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.img-decypt {
  width: 100%;
  height: 100%;
  .vanImage {
    width: 100%;
    height: 100%;
    background: #ededed;
    /deep/ .van-image__error {
      background: #ededed;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
    .imgLoading {
      width: 60%;
      height: 60%;
    }
  }
}
</style>
